// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
//font
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

header {
    //border-bottom: 1px solid $blue;
}

a {
    color: $body-color;
    font-size: 1rem;
}

a:hover {
    color: $blue;
    text-decoration: none;
}

.gray1 {
    color: $gray-100;
}

.gray2 {
    color: $gray-200;
}

.gray3 {
    color: $gray-300;
}

.gray4 {
    color: $gray-400;
}

.gray5 {
    color: $gray-500;
}

.gray6 {
    color: $gray-600;
}

//

.banner .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: none;
    background: #999;
}

.navbar ul li {
    margin-left: 20px;
}

.navbar .nav-link {
    font-size: 16px;
    text-align: center;
}

.navbar .nav-link span {
    font-size: 12px;
    display: block;
}

#tabs li a.nav-link {
    padding-bottom: 32px;
}

#tabs li .active {
    background: url('../img/arrow.png') center bottom no-repeat;
}

@media (min-width: 1990px) {
    .banner {
        width: 80%;
    }
}

.bb-line {
    border-bottom: 3px solid $primary;
    width: 60px;
    display: block;
}

.message {
    font-size: 16px;
    line-height: 27px;
}

.news .list a {
    font-size: 20px;
    padding-bottom: 15px;
    font-style: normal;
}

.news .list a.active {
    border-bottom: 1px solid $primary;
    color: $primary;
    background: url('../img/arrow.png') center 30px no-repeat;
}

.gray-xs {
    color: $gray-300;
    font-size: 2.8rem;
}

.font12 {
    font-size: 12px;
}

.section {
    h1 {
        color: $gray-300;
        font-size: 5.6rem;
        font-weight: normal;
        margin-right: 10px;
    }

    b {
        font-size: 2.2rem;
        color: $primary;
    }

    p {
        font-size: 1.5rem;
    }
}

@media (max-width: 500px) {
    .gray-xs {
        font-size: 1.8rem;
    }
    .section {
        h1 {
            display: none;
        }
    }
}

.doctor ul li {
    width: 180px;
    color: #333;
    background: linear-gradient(to bottom, white, $primary, white);
    padding-left: 2px;

    p {
        bottom: 0;
        left: 0;
    }

    div {
        background: #fff;
        overflow: hidden;
    }

    b {
        line-height: 1.5rem;
        font-size: 1.3rem;
    }

    span {
        font-size: 14px;
        line-height: 1.2rem;
        border-right: 1px solid #333;
        padding-right: 25px;
    }
}

.doctor ul li:nth-child(2n) {
    height: 550px;
}

.doctor ul li:last-child {
    padding-right: 2px;
}

.show ul li a.disabled {
    color: $gray-400;
}

.show ul li a.active {
    color: $primary;
}

.img-group {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;

    span {
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        display: block;
        width: 100%;
        padding: 6px;
    }
}

footer {
    background: url('../img/footer.png') center bottom no-repeat;

    li:nth-child(n+2):before {
        content: "| ";
        padding-right: 10px;
    }
}

/* about */
.about-title {
    margin: 20px auto;
    text-align: center;

    height: 50px;
    border: 3px solid $gray-400;

    div {
        width: 80%;
        background: white;
        margin: -30px auto 0;
        height: 80px;
    }

    h2 {
        color: $gray-400;
        font-size: 2rem;
    }

    h1 {
        color: $dark;
        font-size: 3rem;
    }
}

#about {
    p {
        font-size: 16px;
        line-height: 2;
    }

    ul li {
        margin-right: 20px;
        text-align: center;
        border-radius: 20px;
        background: $secondary;

        img {
            padding: 20px;
        }

        span {
            background: $gray-300;
            display: block;
            padding: 10px;
            border-radius: 0 0 20px 20px;
        }
    }

    ul li:hover {
        background: $primary;
    }
}

#features {
    margin-left: 40px;

    .number {
        align-self: flex-start;
        color: $primary;
        border-top: 2px solid $primary;
    }

    p {
        line-height: 1.5;
    }

    div {
        height: 238px;
    }

    div:nth-child(2n+1) {
        width: 75%;
    }

    div:nth-child(2n) {
        width: 85%;
        margin-left: 30px;
    }
}

#team {
    img {
        width: 150px;
        height: 180px
    }

    p {
        font-size: 12px;
        line-height: 24px;
    }
}

/* åºé¨å·¥å·æ  */
#triangle-bottomleft {
    width: 0;
    height: 0;
    border-bottom: 30px solid #999;
    border-right: 30px solid transparent;
    position: absolute;
    right: -30px;
    top: 0;
}

#footbar {
    position: fixed;
    bottom: 0;
    z-index: 999;
    height: 80px;

    ul {
        list-style: none;
        margin: 0;

        li:nth-child(3):before, li:nth-child(4):before {
            content: "| ";
            color: #f9f9f9;
            padding-right: 10px;
        }

        li {
            float: left;
            margin: 10px;

            button {
                color: #fff;
            }


            i.fa {
                color: #fff;
                font-size: 40px;
                margin-right: 15px;
            }
        }

        li.tel {
            background: $green;
            border-radius: 0;
            margin-top: -30px;
            height: 120px;
            border: none;
            padding: 10px;
            position: relative;

            button {
                text-align: left;
                margin-left: 10px;
            }

            p {
                font-size: 30px;
                margin: 0;
            }
        }
    }
}
#qrcodes{
    position: fixed;
    z-index: 888;
    right: 0;
    top: 30%;
    border-right: none;
    text-align: center;
    background: rgba(255,255,255,0.7);
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
    }
    ul li{
        padding: 10px;
        margin: 0;
        border-bottom: 1px solid #efefef;
    }
    ul li img{
        width:100px;height:100px;
    }
}

#top{
    position:fixed;right:10px;bottom:120px;color: #fff;
    a{
        color: #fff;
        i.fa{font-size: 60px;}
    }
}
#center{
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li{
        float: left;
        margin: 10px;
        i{
            font-size: 50px;
            line-height: 60px;
        }
        img{
            width: 50px;
        }
    }
}
